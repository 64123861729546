import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SyncLoader } from "react-spinners";
import { MdOutlineArrowForward } from "react-icons/md";
import { Link } from "react-router-dom";
import AppBar from "../components/common/AppBar/AppBar";
import NavBar from "../components/common/NavBar/NavBar";
import JourneyDataContainer from "../components/Journey-information/JourneyDataContainer";
import TrainBox from "../components/Journey-information/TrainBoxRectangle";
import SquareBox from "../components/Journey-information/SquareBox";

function JourneyLayout() {
	const [vehicleData, setVehicleData] = useState([]);
	const [portions, setPortions] = useState([]);

	const data = useSelector((state: any) => state.vehicle.data);
	const dataLoader = useSelector((state: any) => state.vehicle.loader);

	function getPunctuality(sec: any) {
		if (sec < 0) return `Early by ${Math.abs(sec)} sec `;
		if (sec === 0) return "On Time";
		if (sec > 0) return `Late by ${Math.abs(sec)} sec`;
		return null;
	}

	function getLocation(location: any) {
		if (location === null) return "";
		if (location === undefined) return "";
		return location;
	}

	useEffect(() => {
		setVehicleData(data.schedule);
		setPortions(data.portions?.map((e: any) => e.assemblies));
	}, [data]);

	return (
		<div>
			{dataLoader ? (
				<div
					className="text-center empty-response"
					style={{
						position: "absolute",
						color: "red",
						marginLeft: "50%",
						marginTop: "230px",
						fontSize: "20px",
					}}
				>
					<SyncLoader loading={dataLoader} color="#1d2b50" size={10} />
				</div>
			) : (
				<>
					<div>
						<NavBar text="Calling Points" />
					</div>
					<div className="container">
						<div className="container_train_info">
							<h2>Train information</h2>

							<Link to="/view_facilities">
								<span>View Facilities</span>
							</Link>
						</div>
						<div className="train-info">
							<p>
								Route: &nbsp;
								<span style={{ color: "blue", fontWeight: "200" }}>
									{`${data.origin.location_name} - ${data.destination.location_name}`}
								</span>
							</p>
							<p>
								Last Location: &nbsp;
								<span style={{ color: "blue", fontWeight: "200" }}>
									{getLocation(data.last_location.location_name)}
								</span>
							</p>
							<p>
								Punctuality: &nbsp;
								<span style={{ color: "blue", fontWeight: "200" }}>
									{/* {`${data.origin.delay_secs}`} */}{" "}
									{getPunctuality(data?.origin?.delay_secs)}
								</span>
							</p>
							<p>
								Service Group: &nbsp;
								<span style={{ color: "blue", fontWeight: "200" }}>
									{`${data.origin.location_name}`}
								</span>
							</p>
							<p>
								Company: &nbsp;
								<span style={{ color: "blue", fontWeight: "200" }}>
									{`${data.origin.tiploc}`}
								</span>
							</p>
						</div>
						<div className="container_trains_container">
							{portions?.map((e: any) =>
								e.map((f: any) =>
									f.vehicles.map((u: any) => {
										if (u.occupancy_ind === "H") {
											return <TrainBox trainColor="#FD3E24" />;
										}
										if (u.occupancy_ind === "M") {
											return <TrainBox trainColor="#FECE24" />;
										}
										if (u.occupancy_ind === "L") {
											return <TrainBox trainColor="#5DE446" />;
										}

										return <TrainBox trainColor="#c1c1c1" />;
									}),
								),
							)}
							<MdOutlineArrowForward />
						</div>
						<div className="container_color_codes">
							<h4>Seats:</h4>
							<span>
								<SquareBox trainColor="#5DE446" />
								<p>Many</p>
							</span>
							<span>
								<SquareBox trainColor="#FECE24" />
								<p>Some</p>
							</span>
							<span>
								<SquareBox trainColor="#FD3E24" />
								<p>Limited</p>
							</span>
							<span>
								<SquareBox trainColor="#c1c1c1" />
								<p>No data</p>
							</span>
						</div>
					</div>
					<hr />
					<div>
						<JourneyDataContainer
							data={vehicleData}
							lastLocation={data?.last_location?.location_name}
						/>
					</div>

					<div className="container_appbar">
						<AppBar />
					</div>
				</>
			)}
		</div>
	);
}

export default JourneyLayout;
