// import styled from "styled-components";
import { useSelector } from "react-redux";
import { MdOutlineArrowUpward } from "react-icons/md";
import AppBar from "../components/common/AppBar/AppBar";
import NavBar from "../components/common/NavBar/NavBar";
import FacilityContainer from "../components/View-facilities/FacilityContainer";
import FacilityHeader from "../components/View-facilities/FacilityHeader";

// const MainWrapper = styled.div`
// 	margin-bottom: 64px;
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	&:first-child > div > div{
// 		border-radius: 12px 12px 12px 12px;
// 	}
// `;

function FacilitiesLayout() {
	const data = useSelector((state: any) => state.vehicle.data);
	const VehicleCount = useSelector(
		(state: any) => state.vehicle.vehicles,
	).length;

	function getPunctuality(sec: any) {
		if (sec < 0) return `Early by ${Math.abs(sec)} sec `;
		if (sec === 0) return "On Time";
		if (sec > 0) return `Late by ${Math.abs(sec)} sec`;
		return null;
	}

	return (
		<>
			<NavBar text="Train Information" />
			<hr />
			<FacilityHeader
				text="Train to London Waterloo"
				subtext={`The train has ${VehicleCount} carriages`}
			/>

			<div className="train-info">
				<p>
					Route: &nbsp;
					<span style={{ color: "blue", fontWeight: "200" }}>
						{`${data?.origin?.location_name} - ${data?.destination?.location_name}`}
					</span>
				</p>
				<p>
					Last Location: &nbsp;
					<span style={{ color: "blue", fontWeight: "200" }}>
						{`${data?.last_location?.location_name}`}
					</span>
				</p>
				<p>
					Punctuality: &nbsp;
					<span style={{ color: "blue", fontWeight: "200" }}>
						{/* {`${data.origin.delay_secs}`} */}{" "}
						{getPunctuality(data?.origin?.delay_secs)}
					</span>
				</p>
				<p>
					Service Group: &nbsp;
					<span style={{ color: "blue", fontWeight: "200" }}>
						{`${data?.origin?.location_name}`}
					</span>
				</p>
				<p>
					Company: &nbsp;
					<span style={{ color: "blue", fontWeight: "200" }}>
						{`${data?.origin?.tiploc}`}
					</span>
				</p>
			</div>
			<div
				style={{
					marginInline: "30px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: "-2px",
					marginTop: "16px",
					marginLeft: "-107px",
				}}
			>
				<MdOutlineArrowUpward />
				<p style={{ marginInline: "30px" }}> Train Direction</p>
			</div>
			<div className="JourneyMainWrapper">
				<FacilityContainer />
			</div>
			<AppBar />
		</>
	);
}

export default FacilitiesLayout;
