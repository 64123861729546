import { BiMenuAltRight } from "react-icons/bi";

function Header() {
	return (
		<header className="header_container">
			<div className="header_container_content">
				<div className="header_container_logo_div">
					<img className="header_container_logo" src="/logo.png" alt="/" />
				</div>
				<nav className="header_container_content_nav">
					<ul>
						<li>
							<a href="/">Buy tickets </a>
						</li>
						<li>
							<a href="/"> Train tickets </a>
						</li>
						<li>
							<a href="/"> Plan my journey </a>
						</li>
						<li>
							<a href="/"> Travelling with us </a>
						</li>
						<li>
							<a href="/">Help and support </a>
						</li>
					</ul>
				</nav>
				<div>
					<button
						type="button"
						style={{ display: "none" }}
						className="header_container_content_hamburger"
					>
						<BiMenuAltRight />
					</button>
				</div>
			</div>
		</header>
	);
}

export default Header;
