import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import LandingContainer from "./components/Landing-page/LandingContainer";
import JourneyLayout from "./screens/JourneyLayout";
import FacilitiesLayout from "./screens/FacilitiesLayout";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LandingContainer />} />
				<Route path="/:uid" element={<LandingContainer />} />
				<Route path="/journey" element={<JourneyLayout />} />
				<Route path="/view_facilities" element={<FacilitiesLayout />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
