import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
	value: number;
}

const initialState: AuthState = {
	value: 0,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {},
});

export default authSlice.reducer;
