import styled from "styled-components";

type ButtonProps = {
	text: string;
};

const StyledButton = styled.button`
	background: #006def;
	color: #fff;
	border: none;
	width: 300px;
	height: 40px;
	border-radius: 8px;
	font-weight: bold;
	text-transform: uppercase;
	margin-block: 8px;
	font-family: "Poppins";
	cursor: pointer;

	&:hover {
		background: #1781ff;
	}
`;

function Button({ text }: ButtonProps) {
	return <StyledButton>{text}</StyledButton>;
}

export default Button;
