import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function NavBar({ text }: { text: String }) {
	type ScriptEvent = (props: any) => any;
	const navigate: ScriptEvent = useNavigate();
	return (
		<div className="nav_container">
			{" "}
			<span
				aria-hidden
				className="nav_container_arrow"
				onClick={() => navigate(-1)}
			>
				<BiArrowBack size={36} />
			</span>
			<span className="nav_container_text">{text}</span>
		</div>
	);
}

export default NavBar;
