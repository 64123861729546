import { MdDirectionsSubway } from "react-icons/md";
import { FormatDateTimeToDate } from "../../helpers/helpers";
import JourneyDataBox from "./JourneyDataBox";

function JourneyDataContainer({
	data,
	lastLocation,
}: {
	data: any;
	lastLocation: any;
}) {
	return (
		<div className="main_container">
			<div className="main_container_vertical_line">{}</div>
			<div className="main_container_data_box">
				{data &&
					data.length > 0 &&
					data.map((e: any) => {
						return (
							<div
								style={{
									position: "relative",
									display: "flex",
									justifyContent: "center",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<div
									style={{
										width: "1px",
										position: "relative",
										height: "90px",
										background: "#8A46ED",
										color: "black",
									}}
								/>

								{lastLocation === e.location_name ? (
									<span
										style={{
											position: "absolute",
											left: -12,
											top: "50%",
											width: "25px",
											fontSize: "25px",
											height: "25px",
											background: "#8A46ED",
											color: "white",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<MdDirectionsSubway />
									</span>
								) : null}
								<JourneyDataBox
									stationName={e.location_name}
									crossedTime={FormatDateTimeToDate(e.arrival?.estimated_time)}
									time={FormatDateTimeToDate(e.arrival?.working_time)}
									departureInfo=""
									platform={e.platform}
								/>
							</div>
						);
					})}
			</div>
		</div>
	);
}

export default JourneyDataContainer;
