import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getVehicleInformation } from "../../app/store/vehicleSlice";
import AppBar from "../common/AppBar/AppBar";
import Button from "../common/Buttons/Button";
import Header from "../Header/Header";

const HeroContainer = styled.div`
	width: 350px;
	min-height: 300px;
	box-shadow: 1px 2px 14px #b4b4b473;
	display: flex;
	background: #e9f0f5;
	justify-content: center;
	align-item: center;
	flex-direction: column;
	padding: 30px;

	h4 {
		padding: 10px;
	}
`;

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LandingContainer() {
	const query = useQuery();
	const Uid = query.get("uid");
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getVehicleInformation(Uid));
	}, [dispatch]);

	return (
		<>
			<Header />
			<div style={{ margin: "0px", padding: "0px" }}>
				<br />
				<br />
				<br />

				<h1
					style={{
						display: "flex",
						justifyContent: "center",
						color: "white",
						paddingTop: "60px",
						padding: "16px",
						fontSize: "20px",
						fontFamily: "Poppins",
					}}
				>
					{/* Welcome, How we may help you today ? */}
				</h1>
				<div
					style={{
						paddingTop: "160px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<HeroContainer>
						<h4>Good Morning, How can we help you today?</h4>

						<Link to="/journey">
							<Button text="Journey Information" />
						</Link>
						<Button text="General Enquiry" />
						<Button text="Report & Feedback" />
						<Button text="Request Assistance" />
					</HeroContainer>
				</div>
				<AppBar />
			</div>
		</>
	);
}

export default LandingContainer;
