import styled from "styled-components";
import {
	MdAccessible,
	MdOutlineDirectionsBike,
	MdVolumeOff,
} from "react-icons/md";
import { useSelector } from "react-redux";

const Container = styled.div`
	margin: 8px;
	display: flex;
	justify-content: start;
	align-item: center;
`;

const FacilityCard = styled.div`
	background: #f6f6f6;
	width: 291px;
	height: 66px;
	margin-left: 20px;
	border-radius: 8px;

	h6 {
		padding: 8px 12px;
	}

	svg {
		width: 28px;
		height: 19px;
		color: white;
		background: #8a46ed;
		margin: -4px 4px;
	}
`;

const TrainElement = styled.div`
	width: 24px;
	border-radius: "6px";
	height: 66px;
	background: ${(props) => (props.color ? props.color : "#D9D9D9")};
`;

function FacilityContainer() {
	const vehicles = useSelector((state: any) => state.vehicle.vehicles);

	function getColor(val: string) {
		const [GREEN, YELLOW, RED, GREY] = [
			"#5DE446",
			"#FECE24",
			"#FD3E24",
			"#c1c1c1",
		];

		if (val === "L") return GREEN;
		if (val === "M") return YELLOW;
		if (val === "H") return RED;
		return GREY;
	}

	function getSeatStatus(val: string) {
		if (val === "L") return "MANY";
		if (val === "M") return "SOME";
		if (val === "H") return "LIMITED";
		return "NO DATA";
	}

	return (
		<>
			{vehicles.map((v: any) => {
				return (
					<Container key={v}>
						<TrainElement color={getColor(v.occupancy_ind)} />
						<FacilityCard>
							<div className="facility-card-top">
								<h6>{v.vehicle_id}:</h6>
							</div>
							<div className="facility-card-bottom">
								<button className="seat-status" type="submit">
									{getSeatStatus(v.occupancy_ind)}
								</button>

								{v.facilities.w_chair_spaces > 0 ? (
									<>
										<MdAccessible /> {v.facilities.w_chair_spaces}
									</>
								) : null}
								{v.facilities.bike_spaces > 0 ? (
									<>
										<MdOutlineDirectionsBike />
										{v.facilities.bike_spaces}
									</>
								) : null}
								{v.facilities.is_quiet_coach === "true" ? (
									<>
										<MdVolumeOff />
										{v.facilities.is_quiet_coach}
									</>
								) : null}
							</div>
						</FacilityCard>
					</Container>
				);
			})}
		</>
	);
}

export default FacilityContainer;
