import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const config = {
	headers: {
		"Content-Type": "application/json",
		"x-api-key": `${process.env.REACT_APP_X_API_KEY}`,
	},
};

export const getVehicleInformation: any = createAsyncThunk(
	"vehicle/getVehicleData",
	async (uid) => {
		return fetch(
			`https://api-dev.iqr.mistralcloud.net/vehicle/data?uid=${uid}`,
			config,
		)
			.then((res) => res.json())
			.catch((err) => console.log(err));
	},
);

const getVehicles = (data: any): any => {
	let vehicles: any = [];
	function updateVehicles(key: any) {
		vehicles = [...vehicles, ...key.vehicles];
	}
	const target = data?.portions[0]?.assemblies;
	target.forEach(updateVehicles);

	return vehicles;
};

const vehicleSlice = createSlice({
	name: "vehicleInformations",
	initialState: {
		api_status: null,
		data: [],
		loader: false,
	},
	reducers: {},

	extraReducers: {
		[getVehicleInformation.pending]: (state: any) => {
			state.api_status = "loading";
			state.loader = true;
		},
		[getVehicleInformation.fulfilled]: (state: any, { payload }) => {
			state.api_status = "success";
			state.loader = false;
			state.data = payload;
			state.destination = payload.destination;
			state.vehicles = getVehicles(payload);
			state.schedule = payload.schedule;
			state.portions = payload.portions;
		},
		[getVehicleInformation.rejected]: (state: any) => {
			state.api_status = "failed";
			state.loader = false;
		},
	},
});

export default vehicleSlice.reducer;
