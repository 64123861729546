function FacilityHeader({ text, subtext }: { text: string; subtext: string }) {
	return (
		<div className="facility-header">
			<h3>{text}</h3>
			<p>{subtext}</p>
		</div>
	);
}

export default FacilityHeader;
