function JourneyDataBox({
	stationName,
	crossedTime,
	time,
	departureInfo,
	platform,
}: {
	stationName: String;
	crossedTime: String;
	time: String;
	departureInfo: String;
	platform: String;
}) {
	return (
		<div className="journey_data_container">
			<div className="journey_data_container_top">
				<h3>{stationName}</h3>
				<span className="journey_data_container_top_crossed_time">
					{crossedTime}
				</span>
				<span className="journey_data_container_top_time">{time}</span>
			</div>
			<div className="journey_data_container_down">
				<span className="journey_data_container_down_departure">
					{departureInfo}
				</span>
				<span className="journey_data_container_down_platform">
					Platform: {platform}
				</span>
			</div>
		</div>
	);
}

export default JourneyDataBox;
