import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import authReducer from "./store/authSlice";
import vehicleReducer from "./store/vehicleSlice";

const reducers = combineReducers({
	auth: authReducer,
	vehicle: vehicleReducer,
});

const persistConfig = {
	key: `root-${process.env.REACT_APP_ENVIRONMENT}`,
	storage,
	whitelist: ["vehicle"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
